@rating-star-color: #495762;

.resource-add-btn {
  margin-left: 8px;
}

.ant-rate.rating {
  color: @rating-star-color;
}

.ant-rate.rating span.anticon-star {
  font-size: 14px;
  padding: 0;
}

.resource-thumbnail {
  width: 64px;
  height: 64px;
}

.resource-appfilter-wrapper {
  padding: 10px;
}

.resource-appfilter-btn-wrapper {
  margin-top: 10px;
}

.resource-appfilter-btn-wrapper button {
  width: 70px;
}

.resource-form-loading {
  height: 50vh;
}

.resource-table {
  overflow-x: auto;
}

.resource-input-filter {
  width: 188px;
  margin-bottom: 8px;
  display: 'block';
}

.upload-image {
  .ant-btn {
    height: 32px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .ant-upload.ant-upload-select.ant-upload-select-picture {
    width: 100%;
  }
  .ant-upload {
    display: flex;
    justify-content: space-between;
    width: 70px;
  }
  .ant-upload.ant-upload-select.ant-upload-select-picture {
    border: 1px solid #d9d9d9;
    padding: 6px;
    border-radius: 4px;
  }
}

.active-checkbox {
  svg {
    color: green;
  }
}

.inactive {
  svg {
    color: red;
  }
}

button.ant-btn.ant-btn-primary.upload-button {
  position: absolute;
  right: 16px;
}

.resource-form {
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
    margin-left: 0px;
  }
  .ant-form-item-label > label::before {
    content: ' ';
    margin-left: 10px;
    line-height: 1;
  }
}

.ant-form-item-explain.ant-form-item-explain-error {
  margin-bottom: 10px;
}

.companies-modal {
  .ant-modal-body {
    max-height: 500px;
    overflow: auto;
    font-size: 16px;
  }
}