.gx-main-content-wrapper {
  position: relative;
  padding-bottom: 32px !important;
}
.ant-popover-inner-content {
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left.user-menu {
    border-right: none;
  }
}

.user-menu {
  .ant-menu-item .anticon,
  .ant-menu-submenu-title .anticon {
    margin-right: 0px;
  }
}
