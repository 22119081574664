.webinar-sync-btn {
  margin-left: 8px;
}

.comma-separated-list {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.webinar-appfilter-wrapper {
  padding: 10px;
}

.webinar-appfilter-btn-wrapper {
  margin-top: 10px;
}

.webinar-appfilter-btn-wrapper button {
  width: 70px;
}

.webinar-form-loading {
  height: 50vh;
}

.webinar-table {
  overflow-x: auto;
}
