.add-uilabel {
  float: right;
}

.delete-uilabel {
  right: 12px;
}

.ant-modal-wrap.ant-modal-centered {
  .ant-modal.uilabel-modal {
    width: 908px;
  }
}

.app-select {
  min-width: 400px;
  width: auto;
  margin-right: 10px;
}

.language-select {
  min-width: 200px;
  width: auto;
  margin-right: 10px;
}
